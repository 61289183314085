import { AxiosPromise } from 'axios';
import HttpAdapter from '@/adapters/HttpAdapter';

export default class SummaryApi {
    protected uri: string = process.env.VUE_APP_BACKEND_URI;

    public getSummary(): AxiosPromise {
        return HttpAdapter.get(`${this.uri}/api/admin/summary/count`);
    }
}
