






































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component
export default class SummaryCard extends Vue {
    @Prop({ required: true, type: Number })
    public count!: number;

    @Prop({ required: true, type: String })
    public title!: string;

    @Prop({ required: true, type: String })
    public icon!: string;

    @Prop({ required: true, type: String })
    public color!: string;

    @Prop({ required: true, type: String })
    public path!: string;
}
