






























import Vue from 'vue';
import SummaryModel from '@/models/SummaryModel';
import { Component } from 'vue-property-decorator';
import SummaryApi from '@/services/api/SummaryApi';
import SummaryCard from '@/components/common/SummaryCard.vue';

@Component({
    components: {
        SummaryCard
    }
})
export default class Dashboard extends Vue {
    public counts: Record<string, number> = {};

    public summary: Record<string, Record<string, string>> = SummaryModel;

    public summaryCount: number = 3;

    public loading: boolean = false;

    public mounted(): void {
        this.getCount();
    }

    public async getCount(): Promise<void> {
        this.loading = true;

        try {
            const response = await (new SummaryApi()).getSummary();

            this.counts = response.data;
        } catch (e) {
            console.error(e);
        } finally {
            this.loading = false;
        }
    }
}
