export default {
    users_count: {
        title: 'Users',
        icon: 'mdi-account-group',
        color: 'success',
        route: 'users'
    },
    resumes_count: {
        title: 'Resumes',
        icon: 'mdi-file-account',
        color: 'warning',
        route: 'resumes'
    },
    cover_letters: {
        title: 'Cover Letters',
        icon: 'mdi-file-account',
        color: 'teal',
        route: 'cover-letters'
    },
    templates_count: {
        title: 'Resume Templates',
        icon: 'mdi-format-list-bulleted-square',
        color: 'light-green',
        route: 'resume-templates'
    },
    cover_templates_count: {
        title: 'Cover Letters Templates',
        icon: 'mdi-format-list-bulleted-square',
        color: 'primary',
        route: 'cover-letter-templates'
    }
};
